<template>
	<vuestic-layout v-layout>
		<div class="ddh-sidebar" :class="opened ? 'show-sidebar' : 'hide-sidebar'">
			<div class="sidebar-fixed">
				<div>
					<div class="menu-control" v-if="isMobile">
						<i class="" v-if="!opened" @click="toggleSidebar(true)">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="36"
								height="36"
								viewBox="0 0 36 36"
							>
								<g fill="#fff" fill-rule="evenodd" transform="translate(7 9)">
									<rect width="22" height="3" rx="1.5" />
									<rect width="22" height="3" y="8" rx="1.5" />
									<path
										d="M0 17.5c0-.828.67-1.5 1.495-1.5h19.01c.826 0 1.495.666 1.495 1.5 0 .828-.67 1.5-1.495 1.5H1.495A1.494 1.494 0 0 1 0 17.5z"
									/>
								</g>
							</svg>
						</i>
						<i class="" v-if="opened" @click="toggleSidebar(false)">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="37"
								height="36"
								viewBox="0 0 37 36"
							>
								<g
									fill="#fff"
									fill-rule="evenodd"
									transform="translate(9.867 9)"
								>
									<rect
										width="22"
										height="3"
										x="-2"
										y="8"
										rx="1.5"
										transform="rotate(45 9 9.5)"
									/>
									<rect
										width="22"
										height="3"
										x="-2"
										y="8"
										rx="1.5"
										transform="scale(-1 1) rotate(45 0 -12.228)"
									/>
								</g>
							</svg>
						</i>
					</div>
					<span class="logo">
						<img
							width="127"
							:src="require('@/assets/primary.svg')"
							alt="Dentro Educa"
							class
						/>
					</span>
				</div>

				<div class="ddh-sidebar-items">
					<ul>
						<li>
							<div class="person-menu" v-if="isMobile">
								<router-link :to="{ name: 'summary' }">
									<span class="ico-menu">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink"
											width="36"
											height="36"
											viewBox="0 0 36 36"
										>
											<defs>
												<path
													id="a"
													d="M9.868 26.825A11.968 11.968 0 0 1 6 18c0-6.627 5.373-12 12-12s12 5.373 12 12c0 3.49-1.49 6.632-3.868 8.825a8.73 8.73 0 0 0-16.264 0zM18 11.455a4.364 4.364 0 1 0 0 8.727 4.364 4.364 0 0 0 0-8.727z"
												/>
											</defs>
											<g fill="none" fill-rule="evenodd">
												<!-- <path fill="#FFF" d="M-568-206H872v753H-568z"/> -->
												<g>
													<circle
														cx="18"
														cy="18"
														r="12"
														stroke="#fff"
														stroke-width="2"
													/>
													<use fill="#fff" xlink:href="#a" />
												</g>
											</g>
										</svg>
									</span>
									<span>{{ managerCredential.user.name }}</span>
								</router-link>
							</div>
						</li>

						<li>
							<router-link :to="{ name: 'dashboard-manager' }">
								<span class="ico-menu">
									<svg
										width="32"
										height="32"
										viewBox="0 0 32 32"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M8.57124 5.28572C8.57124 4.02335 9.59459 3 10.857 3C12.1193 3 13.1427 4.02335 13.1427 5.28571V6.42858H18.8572V5.28572C18.8572 4.02335 19.8806 3 21.1429 3C22.4053 3 23.4286 4.02335 23.4286 5.28571V6.42858H25.3333C26.8061 6.42858 28 7.62249 28 9.09525V26.3333C28 27.8061 26.8061 29 25.3333 29H6.66667C5.19391 29 4 27.8061 4 26.3333V9.09525C4 7.62249 5.19391 6.42858 6.66667 6.42858H8.57124V5.28572ZM6.28577 14.4286V25.3618C6.28577 26.0982 6.88273 26.6951 7.61911 26.6951H24.381C25.1174 26.6951 25.7143 26.0982 25.7143 25.3618V14.4286H6.28577Z"
											fill="white"
										/>
									</svg>
								</span>
								<span>Cronograma</span>
							</router-link>
						</li>

						<li
							v-if="!isMobile && managerCredential.settings.active_salesperson"
						>
							<router-link :to="{ name: 'consultant-manager' }">
								<span class="ico-menu">
									<svg
										width="32"
										height="32"
										viewBox="0 0 32 32"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M20.3408 3.7424C20.5167 3.80469 20.5911 4.00987 20.5123 4.179C19.9977 4.99592 19.7 5.9632 19.7 7.00001C19.7 9.92712 22.0728 12.3 25 12.3C26.0366 12.3 27.0038 12.0024 27.8206 11.4879C27.99 11.409 28.1955 11.4835 28.2578 11.6596C28.7384 13.0169 29 14.4779 29 16C29 23.1808 23.1786 29 16 29C8.82137 29 3 23.1808 3 16C3 8.81922 8.82137 3 16 3C17.5221 3 18.9833 3.26163 20.3408 3.7424ZM23.648 23.8265C22.1819 21.1049 19.3068 19 15.9999 19C12.6931 19 9.81807 21.1049 8.35195 23.8264C10.3248 25.7542 13.0236 26.9423 16 26.9423C18.9763 26.9423 21.6751 25.7542 23.648 23.8265ZM16.0001 9.15924C13.6039 9.15924 11.659 11.1027 11.659 13.5C11.659 15.8973 13.6039 17.8408 16.0001 17.8408C18.3963 17.8408 20.3413 15.8973 20.3413 13.5C20.3413 11.1027 18.3963 9.15924 16.0001 9.15924Z"
											fill="white"
										/>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M25 11C27.2091 11 29 9.20914 29 7C29 4.79086 27.2091 3 25 3C22.7909 3 21 4.79086 21 7C21 9.20914 22.7909 11 25 11ZM27.0965 6.10566H25.8592V5.03789C25.8592 4.56341 25.4745 4.17877 25 4.17877C24.5256 4.17877 24.1409 4.56341 24.1409 5.03789V6.10566H23.0175C22.5782 6.10566 22.2222 6.46172 22.2222 6.90094C22.2222 7.34016 22.5782 7.69623 23.0175 7.69623H24.1409V8.94438C24.1409 9.41886 24.5256 9.8035 25 9.8035C25.4745 9.8035 25.8592 9.41886 25.8592 8.94438V7.69623H27.0965C27.5358 7.69623 27.8918 7.34016 27.8918 6.90094C27.8918 6.46172 27.5358 6.10566 27.0965 6.10566Z"
											fill="white"
										/>
									</svg>
								</span>
								<span>Consultores</span>
							</router-link>
						</li>

						<li>
							<router-link
								id="btn-adoptins"
								:to="{ name: 'adoptions-manager' }"
								:class="
									$route.name.includes('adoption') ||
									$route.name.includes('grades-manager')
										? 'router-link-active'
										: ''
								"
							>
								<span class="ico-menu">
									<svg
										width="32"
										height="32"
										viewBox="0 0 32 32"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M13.7666 22.8001C13.8777 22.9112 13.9888 22.989 14.0999 23.0334C14.211 23.0779 14.3333 23.1001 14.4666 23.1001C14.5999 23.1001 14.7221 23.0779 14.8333 23.0334C14.9444 22.989 15.0555 22.9112 15.1666 22.8001L21.4666 16.5001C21.6444 16.3223 21.7333 16.1112 21.7333 15.8667C21.7333 15.6223 21.6444 15.4001 21.4666 15.2001C21.2888 15.0223 21.0721 14.9334 20.8166 14.9334C20.561 14.9334 20.3444 15.0223 20.1666 15.2001L14.4666 20.9001L11.6666 18.1001C11.4888 17.9223 11.2777 17.8334 11.0333 17.8334C10.7888 17.8334 10.5777 17.9223 10.3999 18.1001C10.2221 18.2779 10.1333 18.4945 10.1333 18.7501C10.1333 19.0056 10.2221 19.2223 10.3999 19.4001L13.7666 22.8001ZM7.33325 29.3334C6.79992 29.3334 6.33325 29.1334 5.93325 28.7334C5.53325 28.3334 5.33325 27.8667 5.33325 27.3334V4.66675C5.33325 4.13341 5.53325 3.66675 5.93325 3.26675C6.33325 2.86675 6.79992 2.66675 7.33325 2.66675H18.5333C18.7999 2.66675 19.0555 2.71675 19.2999 2.81675C19.5444 2.91675 19.7666 3.06675 19.9666 3.26675L26.0666 9.36675C26.2666 9.56675 26.4166 9.78897 26.5166 10.0334C26.6166 10.2779 26.6666 10.5334 26.6666 10.8001V27.3334C26.6666 27.8667 26.4666 28.3334 26.0666 28.7334C25.6666 29.1334 25.1999 29.3334 24.6666 29.3334H7.33325ZM19.3666 10.8667H24.6666L18.3666 4.66675V9.86675C18.3666 10.1556 18.461 10.3945 18.6499 10.5834C18.8388 10.7723 19.0777 10.8667 19.3666 10.8667Z"
											fill="white"
										/>
									</svg>
								</span>
								<span>Adoções</span>
							</router-link>
						</li>

						<li>
							<router-link
								:to="{ name: 'manager-books' }"
								:class="
									$route.name.includes('book') ? 'router-link-active' : ''
								"
							>
								<span class="ico-menu">
									<svg
										width="32"
										height="32"
										viewBox="0 0 32 32"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M8.66675 4C7.56218 4 6.66675 4.89543 6.66675 6V26C6.66675 27.1046 7.56218 28 8.66675 28H23.3334C24.438 28 25.3334 27.1046 25.3334 26V25.3333L10.3334 25.3333C9.59703 25.3333 9.00008 24.7364 9.00008 24C9.00008 23.2636 9.59704 22.6667 10.3334 22.6667L25.3334 22.6667V6C25.3334 4.89543 24.438 4 23.3334 4H8.66675Z"
											fill="white"
										/>
									</svg>
								</span>
								<span>Livros</span>
							</router-link>
						</li>
					</ul>

					<div class="encerrar">
						<img
							:src="managerCredential.user.client_logo"
							width="120"
							alt="Logo"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="ddh-header">
			<div class="container-dhh va-row justify-right">
				<div class="info-person">
					<div class="ico-person">
						<div class="person-name">
							<div class="ico">
								<svg
									width="40"
									height="40"
									viewBox="0 0 40 40"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M35.8177 19.9938C35.8177 28.7183 28.7416 35.7885 20.0157 35.7885C11.2898 35.7885 4.21375 28.7183 4.21375 19.9938C4.21375 11.2694 11.2898 4.19922 20.0157 4.19922C28.7416 4.19922 35.8177 11.2694 35.8177 19.9938ZM20.0156 23.949C24.0352 23.949 27.53 26.1962 29.3121 29.5028C26.9141 31.8449 23.6336 33.2885 20.0157 33.2885C16.3978 33.2885 13.1173 31.8449 10.7192 29.5027C12.5014 26.1961 15.9961 23.949 20.0156 23.949ZM14.7391 17.3566C14.7391 14.4439 17.1032 12.0827 20.0159 12.0827C22.9286 12.0827 25.2927 14.4439 25.2927 17.3566C25.2927 20.2693 22.9286 22.6305 20.0159 22.6305C17.1032 22.6305 14.7391 20.2693 14.7391 17.3566Z"
										fill="white"
									/>
								</svg>
							</div>

							<span>
								<b>{{ managerCredential.user.name }}</b>
								<br />
								<span class="type-person"> Líder de consultor </span>
								<svg width="16" height="16" class="fa" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M20.7019 11.9153L16 16.6172L11.2981 11.9153C10.5177 11.1349 9.2556 11.1349 8.47525 11.9153C7.6949 12.6956 7.6949 13.9577 8.47525 14.7381L14.5952 20.8581C15.3756 21.6384 16.6377 21.6384 17.4181 20.8581L23.5381 14.7381C24.3184 13.9577 24.3184 12.6956 23.5381 11.9152L23.5319 11.9092C22.7608 11.1579 21.4898 11.1273 20.7019 11.9153Z"
										fill="#FFFFFF" />
								</svg>

							</span>

							<div class="menu-dropdown">
								<router-link :to="{ name: 'main' }" class="logout">
									Encerrar sessão
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<main
			slot="content"
			id="content"
			class="va-layout gutter--lg fluid no-padding"
			role="main"
		>
			<!-- <app-breadcrumbs/>
      <vuestic-pre-loader
        v-show="isLoading"
        class="pre-loader"
      /> -->
			<router-view />
		</main>
		<!-- <span slot="footer">
      ©2019 - Dentro Educa
    </span> -->
	</vuestic-layout>
</template>

<script>
	import VuesticLayout from "../../vuestic-theme/vuestic-components/vuestic-layout/VuesticLayout";
	import AppNavbar from "./app-navbar/AppNavbar";
	import AppSidebar from "./app-sidebar/AppSidebar";
	import AppBreadcrumbs from "./app-breadcrumbs/AppBreadcrumbs";
	import Layout from "../../vuestic-theme/vuestic-directives/Layout";
	import { mapGetters } from "vuex";
	const axios = require("axios");

	export default {
		name: "app-layout",
		components: {
			VuesticLayout,
			AppNavbar,
			AppSidebar,
			AppBreadcrumbs,
		},
		directives: {
			layout: Layout,
		},
		updated: function () {
			window.addEventListener("resize", this.resizeMenu);
		},
		mounted() {},
		data() {
			return {
				opened: true,
				isMobile: false,

				name: "",
				lastName: "",

				isSubscriber: false,

				currentRoute: null,
				// errors: []
			};
		},
		watch: {
			$route(to, from) {
				this.resizeMenu();
			},
		},
		methods: {
			addResize() {},
			toggleSidebar(opened) {
				this.opened = opened;
			},
			detectSize() {
				var menuStatus = false;
				if (window.innerWidth < 990) {
					menuStatus = true;
				}
				return menuStatus;
			},
			resizeMenu() {
				// var menuStatus = false
				if (window.innerWidth < 990) {
					this.isMobile = true;
					this.opened = false;
				} else {
					this.isMobile = false;
					this.opened = true;
				}
				// return menuStatus
			},
		},
		computed: {
			...mapGetters(["isLoading"]),

			...mapGetters({
				managerCredential: "managerCredential",
				// ...
			}),
		},
	};
</script>

<style lang="scss">
	.encerrar {
		position: absolute;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		width: 80%;
		text-align: center;
		bottom: 13%;
	}
	.logout {
		&:hover {
			color: red !important;
		}
	}
	.no-border-bottom {
		border-bottom: none !important;
	}
	.clube-dentro {
		padding-bottom: 20px !important;
	}
	.btn-know-more {
		border-radius: 40px;
		border: 1px solid #f1edf5;
		background-color: #fff;
		opacity: 1 !important;
		color: #3bd2d6 !important;
		margin-left: 25px !important;
		font-weight: 900 !important;
		display: block !important;
		width: auto;
		padding: 13px 15px !important;
		float: left;
		margin-bottom: 10px;
	}
	.ddh-sidebar {
		float: left;
		max-width: 230px;
		width: 100%;
		height: calc(100vh);
		position: relative;
		overflow: hidden;

		.sidebar-fixed {
			position: fixed;
			width: 100%;
			background-color: #feaf00;
			max-width: 230px;
			height: 100%;

			@media (max-width: 990px) {
				width: 100%;
				max-width: 100%;
			}
		}

		&.hide-sidebar {
			height: 89px;
			overflow: hidden;

			@media (max-width: 990px) {
				height: 51px;
			}

			.sidebar-fixed {
				width: 100%;
				position: relative;
				max-width: 100%;
			}
		}

		@media (max-width: 990px) {
			max-width: 100%;
			width: 100%;
			overflow: overlay;
			z-index: 2;
			position: relative;
		}

		.logo {
			height: 89px;
			text-align: center;
			padding-top: 22px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			margin-bottom: 10px;
			display: block;

			@media (max-width: 990px) {
				margin-bottom: 0px;
				padding-top: 10px;
				height: 51px !important;

				svg {
					width: 100%;
					max-width: 90px;
				}
			}
			svg {
				margin: 0 auto;
			}
		}

		.menu-control {
			position: absolute;
			left: 20px;
			top: 8px;
			color: white;
			font-size: 24px;
		}
	}

	.ddh-sidebar-items {
		position: relative;
		height: calc(100vh);

		&.ddh-sidebar-items ul {
			list-style: none;
			padding-left: 0px;
			border-bottom: none;
			padding-bottom: 10px;

			li {
				a {
					opacity: 0.7;
					position: relative;
					height: 50px;
					padding-left: 25px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					// cursor: pointer;
					text-decoration: none;
					color: white;
					font-weight: 400;

					&:hover {
						color: white;
						opacity: 1;
					}

					&.router-link-active {
						// background-color:#4965bc;
						opacity: 1;
						color: white;
						font-weight: bold;
						:before {
							content: "";
							position: absolute;
							width: 5px;
							background-color: #fff;
							left: 0;
							height: 100%;
							top: 0px;
						}
					}
				}

				.person-menu {
					opacity: 0.85;
					position: relative;
					height: 64px;
					padding-left: 25px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					cursor: default;
					text-decoration: none;
					// background-color: #4965bc;
					color: white;
					padding-left: 0px;

					a {
						//padding-left: 0px;

						// &.router-link-active{
						//   span{
						//     &:before{
						//       display: none;
						//     }
						//   }
						// }
					}
				}
				svg {
					opacity: 0.85;
					margin-left: -5px;
					margin-right: 10px;
					path {
						fill: #fff;
					}
				}
			}
		}

		.clube-dentro {
			span {
				opacity: 0.7;
				position: relative;
				height: 50px;
				padding-left: 25px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				-ms-flex-direction: row;
				flex-direction: row;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				// cursor: pointer;
				text-decoration: none;
				color: white;
				font-weight: 400;
				font-size: 14px;
				font-weight: bold;
			}
			li:first-child a {
				font-size: 14px;
			}

			li a {
				&.router-link-active {
					// background-color:#4965bc;
					opacity: 1;
					color: white;

					&:before {
						display: block;
						content: "";
						position: absolute;
						width: 5px;
						background-color: #fff;
						left: 0;
						height: 100%;
						top: 0px;
					}
				}
			}

			a {
				height: 40px !important;
			}
		}
	}

	.ddh-header {
		width: 100%;
		height: 89px;
		background-color: #f98e00;

		.justify-right {
			justify-content: right;
			height: 100%;
		}
		@media (max-width: 990px) {
			width: 100%;
			height: 126px;
			background-color: #4965bc;
			display: none;
		}
		.info-bookLimit {
			float: left;
			margin-left: 30px;
			margin-top: 30px;
			cursor: default;
			z-index: 9;
			position: relative;

			.booklimit-value {
				font-family: Roboto;
				color: #ffffff;
				font-size: 18px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
			}
		}

		.info-person {
			float: right;
			margin-right: 8px;
			margin-top: 0px;
			cursor: pointer;
			text-transform: uppercase;
			z-index: 9;
			position: relative;
			display: flex;
			align-items: center;
			@media (max-width: 990px) {
				margin: 0 auto;
				margin-top: 12px;
				float: none;
				display: block;
				text-align: center;
			}

			&:hover {
				.ico {
					opacity: 0.65;
				}
				.person-name span {
					opacity: 0.65;
				}
				.person-name .menu-dropdown {
					display: block;
				}
			}

			// &:hover{
			//   .menu-dropdown{
			//     display: block;
			//   }
			// }
			.ico-person {
				// float: left;
				float: left;
				line-height: 1;

				@media (max-width: 990px) {
					float: none;
					width: 100%;
					text-align: center;
				}
			}

			.person-name {
				margin-right: 15px;

				@media (max-width: 990px) {
					display: inline-block;
					position: relative;
				}
				.ico {
					float: left;
					margin-right: 10px;

					svg {
						path {
							fill: #ffffff;
						}
					}
				}

				.menu-dropdown {
					display: none;
					position: absolute;
					top: 50px;
					// border-top: 10px solid #907feb;
					border-radius: 10px;
					width: 260px;
					border-radius: 10px;
					-webkit-box-shadow: 0 2px 4px 0 rgba(175, 162, 186, 0.4);
					box-shadow: 0 2px 4px 0 rgba(175, 162, 186, 0.4);
					background-color: #ffffff;
					padding: 20px;
					padding-top: 0px;
					padding-bottom: 0px;
					right: 15px;

					@media (max-width: 990px) {
						right: 0;
						left: -41px;
						margin-right: auto;
						margin-left: auto;
						top: 50px;
						z-index: 2;
					}

					a {
						font-family: Nunito;
						font-size: 16px;
						font-weight: 600;
						font-style: normal;
						font-stretch: normal;
						letter-spacing: normal;
						color: #68596e;
						// border-bottom: 1px solid #dfdae4;
						width: 100%;
						display: block;
						height: 57px;
						position: relative;
						line-height: 60px;
						text-transform: initial;

						&:hover {
							color: #1ad3d9;
						}
						&:last-child {
							border-top: 1px solid #dfdae4;
							border-bottom: 0px;
							font-size: 14px;

							&:before {
								display: none;
							}
						}

						&:before {
							content: "\F054";
							color: #1ad3d9;
							position: absolute;
							top: 24px;
							width: 4px;
							height: 4px;
							font: normal normal normal 14px/1 FontAwesome;
							right: 9px;
							margin-left: auto;
							margin-right: auto;
						}
					}

					&:before {
						content: "\F0D8";
						color: #fff;
						position: absolute;
						top: -10px;
						width: 4px;
						height: 4px;
						font: normal normal normal 14px/1 FontAwesome;
						right: 39%;

						@media (max-width: 990px) {
							right: 0px;
							left: 0px;
							margin-left: auto;
							margin-right: auto;
						}
					}
				}
			}

			span {
				float: left;
				height: auto;
				font-stretch: normal;
				letter-spacing: 0.8px;
				font-family: "Roboto";
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 20px;
				color: #ffffff;
				text-align: left;

				&:before {
					content: "";
				}
			}

			.type-person {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;
				/* identical to box height, or 150% */
				text-transform: initial;
				color: #ffffff;
			}

			.fa {
				font-size: 8px;
				position: absolute;
				right: -10px;
				top: 34px;
			}

			.ico-cart {
				position: relative;
				width: 50px;
				height: 50px;
				border-radius: 100px;
				background-color: #ffffff;
				float: right;
				text-align: center;
				padding-top: 7px;

				svg {
					// position: relative;
					// left: -3px;
					// top: 7px;

					path {
						fill: #afa2ba;
					}
				}

				span {
					position: absolute;
					width: 20px;
					height: 30px;
					top: 8px;
					left: 2px;
					right: 0;
					font-size: 9px;
					text-align: center;
					margin-right: auto;
					margin-left: auto;
				}
			}
		}
	}
</style>
